<template>
    <b-modal no-close-on-backdrop title="Ustvari novo sporočilo" size="xl" v-model="show" >
        <b-overlay :show="showLoader">
            <validation-observer ref="validateAddMessage">
                <div class="w-100">
                    <div class="w-100 d-flex">
                        <validation-provider class=" w-100" #default="{ errors }" name="Naziv" rules="required">
                            <b-form-group class="w-100" label="Naziv">
                                <b-form-input
                                    v-model="message.title"
                                />
                            </b-form-group>
                            <small class="text-danger">{{errors[0]}}</small>
                        </validation-provider>
                        <div class="d-flex mx-2 justify-content-center">
                            <div class="mr-1" style="min-width: 100px !important;">
                                <b-form-group>
                                    <template #label>
                                        <div class="text-center">
                                            Pošlji email
                                        </div>
                                    </template>
                                    <div class="d-flex justify-content-center">
                                        <b-form-checkbox style="margin-top: 0.4rem;" switch v-model="message.send_email"/>
                                    </div>
                                </b-form-group>
                            </div>

                            <b-form-group label="Nujno">
                                <b-form-checkbox style="margin-top: 0.4rem;" switch v-model="message.urgent"/>
                            </b-form-group>
                        </div>
                    </div>
                    <validation-provider class=" w-100" #default="{ errors }" name="Sporočilo" rules="required">
                        <div>
                            <b-form-group class="w-100" label="Sporočilo">
                                <text-editor
                                    v-model="message.content"
                                    minHeight="600"
                                />
                            </b-form-group>
                        </div>
                        <small class="text-danger">{{errors[0]}}</small>
                    </validation-provider>
                    <div class="w-100 d-flex">
                        <validation-provider v-if="!isEditing" ref="clientsProvider" class="w-100" #default="{ errors }" name="Stranke" rules="required" :disabled="message.select_all_clients">
                            <b-form-group
                                class="w-100"
                            >
                                <template #label>
                                    <div class="d-flex justify-content-between">
                                        <span v-if="!isEditing">Izberi stranke, ki bodo prejela obvestilo</span>
                                        <span v-else>Izbrane stranke, ki bojo prejela sporočilo</span>
                                        <b-form-checkbox :disabled="isEditing" @input="$refs.clientsProvider.errors = []" v-model="message.select_all_clients">Izberi vse</b-form-checkbox>
                                    </div>
                                </template>
                                <div class="w-100 d-flex" v-if="!message.select_all_clients && !isEditing" >
                                    <v-select
                                        class="w-100"
                                        :options="clients"
                                        :disabled="isEditing"
                                        :multiple="true"
                                        label="alias"
                                        :reduce="ele => ele.id"
                                        v-model="message.client_ids"
                                        :filterBy="filterClientSearch"
                                        appendToBody
                                    >
                                        <template v-slot:option="option">
                                            <div >
                                                <span v-if="option.disabled">{{getOptionName(option.name, 30)}} - {{ getOptionName(option.alias, 20) }} <b-badge class="float-right mr-2" pill variant="danger">Onemogočen</b-badge></span>
                                                <span v-else >{{getOptionName(option.name, 20)}} - {{ getOptionName(option.alias, 20) }}</span>
                                            </div>
                                        </template>
                                    </v-select>
                                    <div class="d-flex align-content-center">
                                        <b-button variant="danger" class="p-0 px-1 ml-1" style="height: 40px;" @click="message.client_ids=[]">Počisiti stranke</b-button>
                                    </div>
                                </div>
                            </b-form-group>
                            <small class="text-danger">{{errors[0]}}</small>
                        </validation-provider>
                        <div v-else-if="message.select_all_clients">
                            <b-badge variant="primary" style="margin: 2px;">Sporočilo bojo prejele vse stranke</b-badge>
                        </div>
                        <div v-else-if="!message.select_all_clients">
                            <span v-for="(client,index) in message.client_ids" :key="index">
                                <b-badge variant="primary" style="margin: 2px;">{{ getClient(client) }}</b-badge>
                            </span>
                        </div>
                    </div>
                    <div class="w-100 d-flex justify-content-center">
                        <validation-provider class="w-100" #default="{ errors }" name="Prioriteta" rules="required">
                            <b-form-group label="Prioriteta" class="w-100 mr-1">
                                <v-select
                                    :options="priorityOptions"
                                    :multiple="false"
                                    label="name"
                                    :reduce="ele => ele.value"
                                    v-model="message.priority"
                                    :clearable="false"
                                    appendToBody
                                >
                                    <template #selected-option="{ name, icon }">
                                        <span><feather-icon :icon="icon" /> {{ name }}</span>
                                    </template>
                                    <template #option="{name, icon}">
                                        <span><feather-icon :icon="icon" /> {{ name }}</span>
                                    </template>
                                </v-select>
                                <small class="text-danger">{{errors[0]}}</small>
                            </b-form-group>
                        </validation-provider>
                        <validation-provider class="w-100" #default="{ errors }" name="Tip" rules="required">
                            <b-form-group label="Tip" class="w-100 ml-1">
                                <v-select
                                    :options="typeOptions"
                                    :multiple="false"
                                    label="name"
                                    v-model="message.type"
                                    :reduce="ele => ele.value"
                                    :clearable="false"
                                    appendToBody
                                >
                                    <template #selected-option="{ name, icon }">
                                        <span><feather-icon :icon="icon" /> {{ name }}</span>
                                    </template>
                                    <template #option="{name, icon}">
                                        <span><feather-icon :icon="icon" /> {{ name }}</span>
                                    </template>
                                </v-select>
                                <small class="text-danger">{{errors[0]}}</small>
                            </b-form-group>
                        </validation-provider>
                    </div>
                    <div class="w-100 my-2 d-flex">
                        <b-form-file
                            class="messageImageUpload"
                            placeholder="Izberi priponke"
                            :multiple="true"
                            style="height: 40px;"
                            ref="fileForm"
                            @change="addImages"
                        >
                            <template #file-name>
                                <div>Izberi priponke</div>
                            </template>
                        </b-form-file>
                    </div>
                    <div v-if="joinedAttachments && joinedAttachments.length > 0">
                        <div style="font-size: 16px;">
                            <div class="d-flex justify-content-between" style="font-size: 14px; font-weight: bold; padding-bottom: 10px; padding-top: 10px;">
                                Priponke:
                            </div>
                            <div style="display: flex; flex-wrap: wrap;">
                                <div class="mb-1 mr-1" style="position:relative;" v-for="(file, id) in joinedAttachments" :key="'slika_' + file.id" @mouseenter="hoveredItem = id" @mouseleave="hoveredItem = -1" >
                                    <div style="position: absolute; z-index: 1000; left: 10px; top: 0;" v-if="file.uploaded">
                                        <b-badge variant="warning" style="font-size: 9px">
                                            Že naloženo
                                        </b-badge>
                                    </div>
                                    <div style="margin-left: 3px; margin-right: 3px; border: 1px solid #d4d4d4;; position: absolute; z-index: 1100; background: #d9d9d9" v-if="hoveredItem === id">
                                        <div style="height: 9rem; width: 12rem; cursor: pointer; overflow-y: hidden; overflow-x: hidden;">
                                            <div class="d-flex">
                                                <div style="padding: 8px; " class="d-flex justify-content-center pt-1">
                                                    <feather-icon :icon="getFileIcon(file)"/>
                                                </div>
                                                <div class="d-flex flex-column align-items-start" style="height: 8rem;">
                                                    <div class="cutText mb-auto" style="margin-top: 13px;">
                                                        {{ file.name }}
                                                    </div>
                                                    <div style="padding-bottom: 5px;">
                                                        <div style="font-size: 12px; font-weight: bold; ">
                                                            {{getFileSize(file.size)}}
                                                        </div>
                                                        <b-button variant="warning" style="width: 30px; height: 20px; padding: 0;" @click="removeSelectedAttachment(file.id)">
                                                            <div class="d-flex align-items-center justify-content-center">
                                                                <feather-icon icon="TrashIcon" style="cursor: pointer;"/>
                                                            </div>
                                                        </b-button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div style="margin-left: 3px; margin-right: 3px; border: 1px solid #d4d4d4;">
                                        <div class="w-100" style="overflow: hidden; height: 7rem; width: 12rem!important;" v-if="file.type.includes('image')">
                                            <img style="cursor: pointer; object-fit: cover; width: 100%; height: 100%;" :src="file.url" alt="Attachment img."/>
                                        </div>
                                        <div v-else-if="file.type === 'application/pdf'">
                                            <div  @click="removeSelectedAttachment(file.minio_id)" >
                                                <pdf style="height: 7rem; width: 12rem; cursor: pointer; overflow-y: hidden;" :src="file.url"/>
                                            </div>
                                        </div>
                                        <div v-else>
                                            <div style="height: 7rem; width: 12rem; cursor: pointer; overflow-y: hidden;" class="d-flex justify-content-center align-items-center">
                                                <feather-icon icon="FileIcon" size="50" color="#b7b7b7"/>
                                            </div>
                                        </div>
                                        <div style="width: 100%;height: 2rem; background: #e7e7e7" class="d-flex align-items-center">
                                            <div style="padding-left: 5px; padding-right: 5px; margin: 0;">
                                                <feather-icon :icon="getFileIcon(file)" style="cursor: pointer;"/>
                                            </div>
                                            <div class="cutTextOne" style="width: 8rem; margin: 0;">
                                                {{ file.name }}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </validation-observer>
        </b-overlay>
        <template #modal-footer>
            <div class="d-flex">
                <div v-if="!isEditing" class="pr-2">
                    <b-button @click="validateSave()" variant="primary">
                        <span class="align-middle">Shrani in nadaljuj</span>
                    </b-button>
                </div>
                <div>
                    <b-button @click="validate()" variant="success">
                        <span v-if="isEditing">
                            <feather-icon icon="EditIcon" class="mr-50"/>
                            <span class="align-middle">Uredi</span>
                        </span>
                        <span v-else>
                            <feather-icon icon="PlusIcon" class="mr-50"/>
                            <span class="align-middle">Ustvari</span>
                        </span>
                    </b-button>
                </div>
            </div>
        </template>
    </b-modal>
</template>
<script>
    import {BOverlay, BButton, BModal, BFormFile, BFormGroup, BBadge, BFormInput, BFormCheckbox} from 'bootstrap-vue'
    import TextEditor from '../../components/TextEditor.vue'
    import vSelect from 'vue-select'

    import FeatherIcon from '@/@core/components/feather-icon/FeatherIcon.vue'

    import { ValidationProvider, ValidationObserver  } from 'vee-validate'
    import { required, requiredClients } from '@validations'
    import '@core/scss/vue/libs/vue-select.scss'
    import pdf from 'vue-pdf'
    import {typeOptions, priorityOptions} from '@/libs/Messages'

    export default {
        components:{BOverlay, BButton, BModal, BFormFile, BFormGroup, BBadge, BFormInput, BFormCheckbox, TextEditor, vSelect, FeatherIcon, ValidationProvider, ValidationObserver, pdf},
        data() {
            return {
                joinedAttachments: [],
                show: false,
                showLoader:false,
                isEditing:false,
                message: {
                    title: '',
                    content: '',
                    urgent: false,
                    type: '',
                    priority: '',
                    select_all_clients: false,
                    client_ids: [],
                    send_email: false,
                    attachments: []
                },
                clients:[],
                hoveredItem:0,
                attachmentsReady: false,
                attachmentsDownloaded: {},
                required,
                requiredClients,
                typeOptions,
                priorityOptions
            }
        },
        methods: {
            async saveAndContinue() {

                try {
                    this.showLoader = true
                    const images = await this.uploadFiles(this.joinedAttachments)

                    if (this.isEditing) {
                        this.message.attachments.push(...images)
                        await this.$http.patch(`/api/admin/v1/message/${this.message.id}`, this.message)
                        this.$printSuccess('Sporočilo uspešno posodobljeno')
                    } else {
                        this.message.attachments = images
                        await this.$http.post('/api/admin/v1/message/', this.message)
                        this.$printSuccess('Sporočilo uspešno ustvarjeno')
                    }

                    this.$emit('update')

                    for (const item of this.joinedAttachments) {
                        this.removeSelectedAttachment(item.id)
                    }

                    this.message.client_ids = []
                } catch (err) {
                    this.$printError(err.response.data.error)
                } finally {
                    this.showLoader = false
                }
            },
            removeSelectedAttachment(id) {
                this.joinedAttachments = this.joinedAttachments.filter(ele => ele.id !== id)
                this.message.attachments = this.message.attachments.filter(ele => ele !== id)
            },
            async uploadFiles(files) {
                const promises = []

                for (const image of files) {
                    if (image.uploaded) {
                        continue
                    }

                    const formData = new FormData()
                    formData.append('file', image.file)
                    const loadPromise = this.$http.post('/api/admin/v1/attachments/', formData, {headers: {'Content-Type': 'multipart/form-data'}})
                    promises.push(loadPromise)
                }

                const images = new Array(promises.length)

                const results = await Promise.all(promises)
                for (const index in results) {
                    const res = results[index]
                    if (res.status === 200) {
                        images[index] = res.data.image_id
                    }
                }

                return images
            },
            getClient(id) {
                const client = this.clients.find(client => client.id === id)
                if (client) {
                    return client.alias
                } else {
                    return ''
                }
            },
            makeId(length) {
                let result = ''
                const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
                const charactersLength = characters.length
                let counter = 0
                while (counter < length) {
                    result += characters.charAt(Math.floor(Math.random() * charactersLength))
                    counter += 1
                }
                return result
            },
            filterClientSearch(option, label, search) {
                const fullName = `${option.name} - ${option.alias}`
                return (fullName || '').toLocaleLowerCase().indexOf(search.toLocaleLowerCase()) > -1
            },
            async addImages(event) {
                const files = event.srcElement.files
                this.hoveredItem = -1

                for (const file of files) {
                    const newId = this.makeId(30)
                    if (this.joinedAttachments.findIndex(ele => ele.name === file.name) === -1) {
                        await file.arrayBuffer().then((arrayBuffer) => {
                            const blob = new Blob([new Uint8Array(arrayBuffer)], {type: file.type})
                            const url = window.URL.createObjectURL(blob)
                            this.joinedAttachments.push({
                                url,
                                name: file.name,
                                type: file.type,
                                size: file.size,
                                uploaded: false,
                                id: newId,
                                file
                            })
                        })
                    }
                }
            },
            async Open(val) {
                this.showLoader = true
                this.joinedAttachments = []
                this.hoveredItem = -1

                if (val) {
                    this.message = JSON.parse(JSON.stringify(val))
                    this.isEditing = true
                    await this.loadMessageAttachments()
                } else {
                    this.message = {
                        title: '',
                        content: '',
                        urgent: false,
                        type: '',
                        priority: '',
                        select_all_clients: false,
                        client_ids: [],
                        send_email: false,
                        attachments: []
                    }

                    this.isEditing = false
                }
                this.showLoader = false
                this.show = true
            },
            Close() {
                this.message = {
                    title: '',
                    content: '',
                    urgent: false,
                    type: '',
                    priority: '',
                    select_all_clients: false,
                    client_ids: [],
                    attachments: []
                }
                this.joinedAttachments = []
                this.hoveredItem = -1
                this.isEditing = false
                this.show = false
            },
            validateSave() {
                this.$refs.validateAddMessage.validate().then((success) => {
                    if (success) {
                        this.saveAndContinue()
                    }
                })
            },
            validate() {
                this.$refs.validateAddMessage.validate().then((success) => {
                    if (success) {
                        this.processMessage()
                    }
                })
            },
            async loadMessageAttachments() {
                try {
                    const res = await this.$http.get(`/api/admin/v1/message/attachments/${this.message.id}`)
                    const downloadedImages = res.data.message_attachments ?? []
                    for (const downloadedImage of downloadedImages) {
                        const attachmentResp = await this.$http.get(`/api/admin/v1/attachments/message/${downloadedImage.minio_id}`, {responseType: 'blob'})
                        const url = window.URL.createObjectURL(new Blob([attachmentResp.data]))

                        if (downloadedImage.file_type === 'application/pdf') {
                            const pdf_src = await pdf.createLoadingTask(url)
                            this.joinedAttachments.push({
                                url: pdf_src,
                                name: downloadedImage.name,
                                id: downloadedImage.id,
                                type: downloadedImage.file_type,
                                size: downloadedImage.byte_size,
                                uploaded: true
                            })
                        } else {
                            this.joinedAttachments.push({
                                url,
                                name: downloadedImage.name,
                                id: downloadedImage.id,
                                type: downloadedImage.file_type,
                                size: downloadedImage.byte_size,
                                uploaded: true
                            })
                        }
                    }

                } catch {
                    this.$printError('Prišlo je do napake!')
                }
            },
            async loadData() {
                this.showLoader = true
                try {
                    const res = await this.$http.get('/api/admin/v1/client/')
                    this.clients = res.data ?? []
                } catch {
                    this.$printError('Prišlo je do napake!')
                } finally {
                    this.showLoader = false
                }
            },
            async processMessage() {
                try {
                    this.showLoader = true
                    const images = await this.uploadFiles(this.joinedAttachments)

                    if (this.isEditing) {
                        this.message.attachments.push(...images)
                        await this.$http.patch(`/api/admin/v1/message/${this.message.id}`, this.message)
                        this.$printSuccess('Sporočilo uspešno posodobljeno')
                    } else {
                        this.message.attachments = images
                        await this.$http.post('/api/admin/v1/message/', this.message)
                        this.$printSuccess('Sporočilo uspešno ustvarjeno')
                    }

                    this.$emit('update')
                    this.Close()
                } catch (err) {
                    this.$printError(err.response.data.error)
                } finally {
                    this.showLoader = false
                }
            },
            getFileIcon(file) {
                try {
                    switch (file.type.split('/')[0]) {
                    case 'image':
                        return 'ImageIcon'
                    case 'audio':
                        return 'HeadphonesIcon'
                    case 'application':
                        if (file.extension === 'pdf') return 'FileTextIcon'
                        else return 'PackageIcon'
                    case 'video':
                        return 'FilmIcon'
                    default:
                        return 'PackageIcon'
                    }
                } catch {
                    this.$printError('Prišlo je do napake!', 3000)
                }

            },
            getFileSize(val) {
                let em = 'B'
                let size = val

                if (size > 1024) {
                    size = size / 1024
                    em = 'KB'
                }

                if (size > 1024) {
                    size = size / 1024
                    em = 'MB'
                }

                if (size > 1024) {
                    size = size / 1024
                    em = 'GB'
                }

                size = Math.round(size * 100) / 100

                return `${size} ${em}`
            },
            getOptionName(nameIn, len = 40) {
                let name = nameIn.substring(0, len)
                if (name.length === len) {
                    name += '...'
                }

                return `${name}`
            }
        },
        mounted() {
            this.loadData()
        }
    }
</script>
<style scoped>
    .cutText{
    margin-top: 5px;
    font-weight: bold;
    font-size: 13px;
    padding-right: 5px;
    overflow: hidden;
    word-wrap: anywhere;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3; /* number of lines to show */
    line-clamp: 3;
    line-height: 1em;
    height: 3em;
    -webkit-box-orient: vertical;
  }

  .cutTextOne{
    margin-top: 5px;
    font-weight: bold;
    font-size: 13px;
    padding-right: 5px;
    overflow: hidden;
    word-wrap: anywhere;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1; /* number of lines to show */
    line-clamp: 1;
    line-height: 1em;
    -webkit-box-orient: vertical;
  }
</style>

<style>
  .messageImageUpload .custom-file-label::after{
    display: none !important;
  }
</style>
