<template>
    <b-overlay :show="showLoader">
        <b-card class="my-1 d-flex align-center" :title="$t('general.system_messages')">
            <b-row>
                <b-col class="text-right">
                    <b-button variant="primary" @click="$refs.addMessageModal.Open()">
                        <feather-icon icon="PlusSquareIcon"/>
                        Ustvari novo sporočilo
                    </b-button>
                </b-col>
            </b-row>
            <basic-table :columns="columns" :data="messages" v-slot="props" :enableRowMarking="true">
                <span v-if="props.column.displayType === 1">
                    <span v-if="props.row.client_ids.length === 1"><b-badge variant="primary" style="margin: 2px;">{{ getClient(props.row.client_ids[0]) }}</b-badge></span>
                    <span v-else-if="props.row.client_ids.length > 1"><b-badge variant="primary" style="margin: 2px;">{{ props.row.client_ids.length }}</b-badge></span>
                </span>
                <span v-else-if="props.column.displayType === 2" class="d-flex justify-content-center">
                    <b-button variant="info" class="mr-1" @click="$refs.previewMessageModal.Open(props.row.id)"><feather-icon icon="BookOpenIcon"/></b-button>
                    <b-button variant="warning" class="mr-1" @click="$refs.addMessageModal.Open(props.row)"><feather-icon icon="EditIcon"/></b-button>
                    <b-button variant="danger" class="mr-0" @click="deleteMessage(props.row.id)"><feather-icon icon="Trash2Icon"/></b-button>
                </span>
                <span v-else-if="props.column.displayType === 3">
                    {{$dayjs(props.row.created_at).format('DD.MM.YYYY H:m:ss')}}
                </span>
                <span v-else-if="props.column.displayType === 4">
                    {{ getUsername(props.row.created_by) }}
                </span>
                <span v-else-if="props.column.displayType === 5" class="d-flex justify-content-center justify-items-center" >
                    <b-badge v-if="props.row.urgent" variant="success">{{ $t('general.yes') }}</b-badge>
                    <b-badge v-else variant="danger">{{ $t('general.no') }}</b-badge>
                </span>
                <span v-else-if="props.column.displayType === 6">
                    <b-badge :variant="getType(props.row.type).variant"> <feather-icon :icon="getType(props.row.type).icon" /> {{ getType(props.row.type).name }} </b-badge>
                </span>
                <span v-else-if="props.column.displayType === 7">
                    <b-badge :variant="getPriority(props.row.priority).variant"> <feather-icon :icon="getPriority(props.row.priority).icon" /> {{ getPriority(props.row.priority).name }} </b-badge>
                </span>
                <div v-else class="rows-limit">
                    <span> {{ props.formattedRow[props.column.field] }}</span>
                </div>
            </basic-table>
            <b-badge/>
        </b-card>
        <add-message ref="addMessageModal" v-on:update="loadData"/>
        <preview-message ref="previewMessageModal" v-on:image-large="openImageFullSize"/>
        <image-display-modal-vue ref="imageModal" />
    </b-overlay>
</template>
<script>
    import {BCard, BOverlay, BButton, BCol, BRow, BBadge} from 'bootstrap-vue'
    import AddMessage from './components/AddMessage.vue'
    import PreviewMessage from './components/PreviewMessage.vue'
    import BasicTable from '@/views/components/BasicTable'
    import ImageDisplayModalVue from './components/ImageDisplayModal.vue'
    import {typeOptions, priorityOptions} from '@/libs/Messages'

    export default {
        components:{BCard, BOverlay, BButton, AddMessage, PreviewMessage, BCol, BRow, BBadge, BasicTable, ImageDisplayModalVue},
        data() {
            return {
                showLoader:false,
                columns: [
                    {
                        label: 'Naziv',
                        field: 'title',
                        displayType: 0,
                        filterOptions: {
                            enabled: true
                        }
                    },
                    {
                        label: 'Avtor',
                        field: 'created_by',
                        displayType: 4,
                        filterOptions: {
                            enabled: true,
                            filterFn: this.filterAuthor
                        }
                    },
                    {
                        label: 'Ustvarjeno',
                        field: 'created_at',
                        displayType: 3,
                        filterOptions: {
                            enabled: false
                        }
                    },
                    {
                        label: 'Stranke',
                        field: 'clients',
                        displayType: 1,
                        filterOptions: {
                            enabled: false
                        },
                        sortable: false
                    },
                    {
                        label: 'Urgentno',
                        field: 'urgent',
                        displayType: 5,
                        filterOptions: {
                            enabled: false
                        },
                        sortable: false
                    },
                    {
                        label: 'Tip',
                        field: 'type',
                        displayType: 6,
                        filterOptions: {
                            enabled: false
                        },
                        sortable: false
                    },
                    {
                        label: 'Prioriteta',
                        field: 'priority',
                        displayType: 7,
                        filterOptions: {
                            enabled: false
                        },
                        sortable: false
                    },
                    {
                        label: 'Možnosti',
                        field: 'options',
                        displayType: 2,
                        filterOptions: {
                            enabled: false
                        },
                        sortable: false
                    }
                ],
                typeOptions,
                priorityOptions,
                messages:[],
                users:[],
                clients: []
            }
        },
        methods:{
            async loadData() {
                this.showLoader = true
                await this.loadMessages()
                await this.loadUsers()
                await this.loadClients()
                this.showLoader = false
            },
            async loadMessages() {
                try {
                    const res = await this.$http.get('/api/admin/v1/message/')
                    this.messages = res.data.messages ?? []
                } catch {
                    this.$printError('Prišlo je do napake!', 3000)
                }
            },
            async loadUsers() {
                try {
                    const res = await this.$http.get('/api/admin/v1/admin_user/')
                    this.users = res.data ?? []
                } catch {
                    this.$printError('Prišlo je do napake!', 3000)
                }
            },
            async loadClients() {
                try {
                    const res = await this.$http.get('/api/admin/v1/client/')
                    this.clients = res.data ?? []
                } catch {
                    this.$printError('Prišlo je do napake!', 3000)
                }
            },
            async deleteMessage(id) {
                try {
                    const confirmDelete = await this.$confirmDialog('Ali res želite izbrisati to sporočilo?')
                    if (!confirmDelete) return
                    await this.$http.delete(`/api/admin/v1/message/${id}`)
                    this.$printSuccess('Sporočilo uspešno izbrisano', 3000)
                    this.loadData()
                } catch {
                    this.$printError('Prišlo je do napake!', 3000)
                }
            },
            getUsername(id) {
                const user = this.users.find(user => user.id === id)
                if (user) {
                    return user.username
                } else {
                    return ''
                }
            },
            getClient(id) {
                const client = this.clients.find(client => client.id === id)
                if (client) {
                    return client.alias
                } else {
                    return ''
                }
            },
            getVariant(client) {
                if (client.read.length) return 'success'
                return 'danger'
            },
            getType(val) {
                const type = this.typeOptions.find(type => type.value === val)
                if (type) {
                    return type
                } else {
                    return {name:'', icon:''}
                }
            },
            getPriority(val) {
                const priority = this.priorityOptions.find(priority => priority.value === val)
                if (priority) {
                    return priority
                } else {
                    return {name:'', icon:''}
                }
            },
            openImageFullSize(val) {
                this.$refs.imageModal.Open(`/api/client/v1/messages/attachment/image/${val.minio_id}`)
            },
            filterAuthor(data, filterString) {
                if (this.users.length > 0) {
                    const index = this.users.findIndex((user) => {
                        return (`${user.username}`).toLocaleLowerCase().indexOf(filterString.toLocaleLowerCase()) > -1
                    })
                    if (index > -1) return true
                }
                return false
            }
        },
        async mounted() {
            await this.loadData()
        }
    }
</script>
<style scoped>
    .rows-limit {
        max-width: 200px;
        text-overflow: ellipsis;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 2; /* number of lines to show */
        line-clamp: 2;
        -webkit-box-orient: vertical;
    }
</style>